const BASE_URL = "https://cdn-jarvisweb.azureedge.net/jarvis-web/sn3-lbs-cdn/assets/web";

export const SPECIFICITY = {
  COMMON: "cmn",
  DASHBOARD: "dsh",
  WEBSITE: "pub",
  LOGO: "logo",
  NONE: ""
}

export const TYPE = {
  ICON: "icons",
  IMAGE: "image",
  IMAGES: "images",
  NONE: "",
  lottie: "lottie"
}


export const ICONS = {
  hamIcon: "ham_icon.svg",
  layoutDot: "layout-dot.svg",
  solutionIdentity: "land-Identity.svg",
  identityFilled: "identityFilled.svg",
  solutionBonus: "land-bonus.svg",
  bonusFilled: "bonusAbsuseFilled.svg",
  solutionUsrProspect: "land-mule.svg",
  usrProspectFilled: "userProspectingFilled.svg",
  solutionOnboarding: "land-onboarding.svg",
  onboardingFilled: "onboardingFilled.svg",
  solutionTakeover: "land-takeover.svg",
  takeoverFilled: "takeoverFilled.svg",
  solutionUnderwriting: "land-underwriting.svg",
  underwritingFilled: "underwritingFilled.svg",
  fbIcon: "facebook.svg",
  xIcon: "twitter.svg",
  lnkdInIcon: "linkedin.svg",
  navDfIcon: "digitalFootprint.svg",
  navDvcIntlIcon: "deviceIntel.svg",
  CrUser360: "user360Filled.svg",
  CrUlternateDat: "alternateDataFilled.svg",
  CrRealTime: "realTimeFilled.svg",
  CrHiddenConnections: "hiddenConnectionsFilled.svg",
  userEx: "userex.svg",
  ML: "ML.svg",
  copyIcon: "copy.svg",
  shareFb: "shareFacebook.svg",
  shareX: "shareTwitter.svg",
  shareLnkdIn: "shareLinkedin.svg",
  greenCheck: "check-circle-green.svg",
  blackCheck: "black-check.svg",
  dfFilled: "dfFilled.svg",
  diFilled: "diFilled.svg",
  adaptive: "adaptive.svg",
  blockFraud: "blockFraud.svg",
  compliance: "compliance.svg",
  minimumFalsePositives: "minFalsePositive.svg",
  profiling: "profiling.svg",
  multiAccount: "multiAccount.svg",
  monitoring: "monitoring.svg",
  userDetection: "userDetection.svg",
  location: "locationFilled.svg",
  mlFilled: "mlFilled.svg",
  LnkdInCof: "LinkedinCof.svg",
  automated: "autoMated.svg",
  predictive: "predictive.svg"
}

export const IMAGES = {
  heroBg: "hero-bg.svg",
  footerBg: "footer-bg.svg",
  heroImage: "landing-hero.svg",
  footerLogo: "footer.svg",
  CrHero: "crHeroImage.svg",
  CrNumbers: "crNumbers.svg",
  CrImBgVector: "impactBgVector.svg",
  OnboardHero: "onboardHero.svg",
  dfHero: "dfHero.svg",
  diHero: "diHero.svg",
  dfRealUsers: "df-real-users.svg",
  dfAccuracy: "df-accuracy.svg",
  dfDecision: "df-informed-decision.svg",
  diGoodVsBad: "di-good-vs-bad.svg",
  diIntegration: "di-integration.svg",
  diHiddenRisk: "di-hidden-risk.svg",
  onePlatform: "onePlatform.svg",
  actionableInsights: "actionableInsights.svg",
  easyIntegration: "easyIntegration.svg",
  lottieBg: "landLottieBg.svg",
  heroGrid: "heroGrid.svg",
  globe: "globe.svg",
  mobGlobe: "globeMob.svg",
  idFraudHero: "idFraudHero.svg",
  bonusAbuseHero: "bonusAbuseHero.svg",
  atoHero: "atoHero.svg",
  arvinderImage: "arvinderCoF.svg",
  amitImage: "AmitCof.svg"
}

export const LOTTIE = {
  integration: "integrationFinalMotion.json",
  useCase: "useCaseFinalMotion.json",
  insightsMotion: "peopleInsights.json",
  landingHero: "heroLottieMotion.json",
  dfMotion: "dfHeroMotion.json",
  diMotion: "diHeroMotionLottie.json",
  lineMotion: "webFooterMotion.json",
  TabLineMotion: "tabFooterMotion.json",
  mobLineMotion: "mobileFooterMotion.json"
}

export const LOGO = {
  signPrimary: "sign-logo-primary.svg",
  primaryWithBlack: "logo-primary-black.svg",
  logoSignP: "logo-sign.svg",
  logoPFull: "logo-primary.svg",
  purpeBlack: "purple-black.svg",
  purpleSign: "purple-sign.svg",
  purpleWhite: "purpleWhite.svg"
}




export const getAssetUrl = (specificity, type, file) => {
  try {
    let finalUrl = BASE_URL

    if (!!specificity) {
      finalUrl = finalUrl + `/${specificity}`
    }

    if (!!type) {
      finalUrl = finalUrl + `/${type}`
    }

    return `${finalUrl}/${file}`;
  } catch (error) {
    // TODO: add logger
    return null
  }
}