import Image from "next/image";

import { cn } from "@/lib/utils"
import { LayoutDot } from "@/components/layout/layout-dot"
import { getAssetUrl, IMAGES, SPECIFICITY, TYPE } from "@/lib/assets";

export const WithLayoutBorders = (WrappedComponent, defaultOptions={}) => (props) => {
  const {
    className,
    hzborders,
    withDots,
    topPadDot,
    bottomPadDot,
    innerContainerClass,
    topBorder,
    bottomBorder,
    bottomDots,
    topDots,
    footerBg,
    skipTopBorder,
    showBgGradient,
    ...otherProps
  } = { ...defaultOptions, ...props };
  const grid  = getAssetUrl(SPECIFICITY.NONE, TYPE.IMAGES, IMAGES.heroGrid)
  return (
    <section className={cn(
      "box-border w-screen relative",
      topDots && "md:border-t md:layout-border",
      bottomDots && "md:border-b md:layout-border",
      withDots && "md:border-y md:layout-border",
      bottomBorder && "md:border-b md:layout-border",
      topBorder && "md:border-t md:layout-border",
      className
    )}>
      {showBgGradient && <div
        className={cn(
          "w-full h-[calc(100%+64px)] absolute -top-[64px] left-0 bottom-0 z-0 bg-heroGradient bg-cover bg-center bg-no-repeat"
        )}
      >
        <Image
          alt="grid image"
          src={grid}
          width={1440}
          height={985}
          className="absolute w-full h-full inset-0"
        />
      </div>}
      <div
        className={cn(
          "w-[100%] relative z-10 box-border px-4 py-12",
          "md:w-[calc(100%-40px)] md:mx-5 md:px-5 md:py-16",
          "xl:w-[1242px] xl:mx-auto xl:py-24",
          innerContainerClass,
          hzborders && "md:border-x md:layout-border",
          (withDots || topDots || bottomDots) && "relative"
        )}
      >
        {(withDots || topDots) && <LayoutDot
          className={`${topPadDot ? "-top-[11px] -left-[11px]" : "-top-[3.5px] -left-[3.5px]"}`}
          withpad={topPadDot}
          footerBg={footerBg}
        />}
        {(withDots || topDots) && <LayoutDot
          className={`${topPadDot ? "-top-[11px] -right-[11px]" : "-top-[3.5px] -right-[3.5px]"}`}
          withpad={topPadDot}
          footerBg={footerBg}
        />}
        {(withDots || bottomDots) && <LayoutDot
          className={`${bottomPadDot ? "-bottom-[11px] -right-[11px]" : "-bottom-[3px] -right-[3px]"}`}
          withpad={bottomPadDot}
          footerBg={footerBg}
        />}
        {(withDots || bottomDots) && <LayoutDot
          className={`${bottomPadDot ? "-bottom-[11px] -left-[11px]" : "-bottom-[3px] -left-[3px]"}`}
          withpad={bottomPadDot}
          footerBg={footerBg}
        />}
        <WrappedComponent {...otherProps} />
      </div>
    </section>
  )
}