import { clsx } from "clsx"
import DOMPurify from "isomorphic-dompurify"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const sanitize = (dirtyHtml) => {
  return DOMPurify.sanitize(dirtyHtml)
}
