import { cn } from "@/lib/utils"

export const LayoutDot = ({
  className, withpad, footerBg
}) => {
  return (
    <div className={cn("absolute bg-white z-10 hidden md:block", className, withpad && "p-2", footerBg && "bg-footer-bg")}>
      <div
        className={cn(
          "w-[6px] h-[6px] rounded-full bg-layoutBorder", footerBg && "bg-gray-700"
        )}
      ></div>
    </div>
  )
}