import { cn } from "@/lib/utils"
import Link from "next/link"

export const NAV_LINK_VARIANTS = {
  PRIMARY: 'PRIMARY',
  OUTLINE: "OUTLINE"
}

export const NavLink = ({
  children,
  href='/',
  className,
  variant,
  ...props
}) => {
  let customClass = ""
  let baseBtnClass = ""
  let hoverClass = ""
  let activeClass = ""

  if (variant === NAV_LINK_VARIANTS.PRIMARY) {
    baseBtnClass = "flex justify-center items-center gap-x-1"
    customClass = "px-8 py-4 !text-tsm xl:!text-tbase leading-115 xl:leading-125  border border-solid border-actnBrdr shadow-smallBrand text-white bg-txtAction rounded-[6px]"
    hoverClass = "hover:!bg-primaryHoverBg hover:!shadow-hoverBrand hover:opacity-1"
    activeClass = "active:opacity-1"
  }

  if (variant === NAV_LINK_VARIANTS.OUTLINE) {
    baseBtnClass = "flex justify-center items-center gap-x-1"
    customClass = "!px-4 !py-2 !text-tsm xl:!text-tbase font-medium border border-inpBrdrNtrl bg-white rounded-[6px] text-txtGrey700"
    hoverClass = "hover:border-scndryHoverBrdr hover:bg-scndryHoverbg hover:text-txtAction hover:opacity-1"
    activeClass = "active:bg-white active:text-txtGrey700 active:opacity-1"
  }

  return (
    <Link
      className={cn(
      "group hover:opacity-70 active:opacity-90",
      customClass,
      baseBtnClass,
      hoverClass,
      className,
      )}
      href={href}
      {...props}
    >
      {children}
    </Link>
  )
}