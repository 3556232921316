'use client'

import { useEffect, useRef, useState } from "react"
import { useWindowWidth } from "@wojtekmaj/react-hooks"
import Image from "next/image"
import { ChevronRightIcon } from "@radix-ui/react-icons"

import { DEMO_ROUTE } from "@/app/routes"
import { WithLayoutBorders } from "@/components/layout/with-layout-borders"
import { NAV_LINK_VARIANTS, NavLink } from "@/components/ui/nav-link"
import { cn, sanitize } from "@/lib/utils"
import { LottiePlayer } from "@/components/common/lottie/player"


const HeroContent = ({
  heading,
  description,
  showScrollingText
}) => {
  return (
    <div
      className={cn(
        "w-full flex flex-col justify-start items-center gap-y-4 relative z-0",
        "md:items-start md:relative md:z-10 md:gap-y-8",
      )}
    >
      <div
        className={cn(
          "w-full flex flex-col justify-start items-center gap-y-3", "md:items-start md:gap-y-6"
        )}
      >
        <div
          className="w-full"
        >
          <h1
            className={cn(
              "w-full text-center text-4xl leading-120 font-semibold text-txtP -tracking-[0.72px]",
              "md:text-start md:text-[calc(12px+3.1963vw)]", "xl:text-7xl xl:leading-125 xl:-tracking-[1.44px]"
            )}
            dangerouslySetInnerHTML={{__html: sanitize(heading)}}
          >
          </h1>
          {!!showScrollingText && <div className="h-fit relative mt-0 overflow-hidden">
            <div
              className={cn(
                "space-y-0 h-[44px] animate-scroll md:h-[calc(17.8px+3.48999vw)]",
                "xl:h-[90px] xl:space-y-0"
              )}
            >
              <h1
                className={cn(
                  "mx-auto w-fit text-center heroTextGradient animate-heroTextgradient flex justify-center items-center md:justify-start whitespace-nowrap",
                  "md:w-full md:text-start",
                  "text-4xl leading-120 font-semibold -tracking-[0.72px] md:text-[calc(12px+3.1963vw)]",
                  "xl:text-7xl xl:leading-125 xl:-tracking-[1.44px]"
                )}
              >Fraud Prevention</h1>
              <h1
                className={cn(
                  "mx-auto w-fit heroTextGradient animate-heroTextgradient flex justify-center items-center md:justify-start whitespace-nowrap",
                  "md:w-full md:text-start mt-0",
                  "text-4xl leading-120 font-semibold -tracking-[0.72px] md:text-[calc(12px+3.1963vw)]",
                  "xl:text-7xl xl:leading-125 xl:-tracking-[1.44px]"
                )}
                style={{
                  marginBlockStart: '0px'
                }}
              >Risk Management</h1>
              <h1
                className={
                  cn(
                    "mx-auto w-fit heroTextGradient animate-heroTextgradient flex justify-center items-center md:justify-start whitespace-nowrap",
                    "md:w-full md:text-start mt-0",
                    "text-4xl leading-120 font-semibold -tracking-[0.72px] md:text-[calc(12px+3.1963vw)]",
                    "xl:text-7xl xl:leading-125 xl:-tracking-[1.44px]"
                  )
                }
                style={{
                  marginBlockStart: '0px'
                }}
              >Exponential Growth</h1>
            </div>
          </div>}
        </div>
        <p
          className={cn(
            "w-full text-center text-xs leading-150 text-txtGrey700",
            "md:text-start md:max-w-[55%] md:text-[calc(4px+1.4263vw)]",
            "xl:text-lg xl:leading-150"
          )}
          dangerouslySetInnerHTML={{__html: sanitize(description)}}
        ></p>
      </div>
      <NavLink
        variant={NAV_LINK_VARIANTS.PRIMARY}
        href={DEMO_ROUTE}
        className={cn(
          "shadow-smallBrand py-3 px-4 xl:py-4 xl:px-8 rounded-lg"
        )}
      >
        <span
          className={cn(
            "text-white text-sm leading-115 font-medium", "xl:text-tmd xl:leading-135"
          )}
        >Book a Demo</span>
        <ChevronRightIcon
          className='text-white h-3 w-3 xl:h-5 xl:w-5'
        />
      </NavLink>
    </div>
  )
}

const HeroImage = ({
  heading,
  image,
  mobImage
}) => {
  return (
    <div
      className={cn(
        "w-full relative aspect-1 -z-10",
        "md:absolute md:w-[48%] md:right-0 bottom-0 md:-bottom-6",
        "xl:w-[580px]"
      )}
      id="heroimagecontainer"
    >
      <Image
        alt={`${heading} image`}
        className={cn(
          "absolute w-full h-full inset-0", !!mobImage && "hidden md:block"
        )}
        src={image}
        height={324}
        width={324}
      />
      {mobImage && <Image
        alt={`${heading} image`}
        className={cn(
          "absolute w-full h-full inset-0 md:hidden"
        )}
        src={mobImage}
        height={324}
        width={324}
      />}
    </div>
  )
}

const HeroLottie = ({
  url,
  lottieClass
}) => {
  return (
    <div
      className={cn(
        "w-full relative aspect-1 -z-10",
        "md:absolute md:w-[48%] md:right-0 md:bottom-0",
        "xl:w-[580px]", lottieClass
      )}
      id="heroimagecontainer"
    >
      <LottiePlayer url={url} />
    </div>
  )
}

const Hero = ({
  heading,
  description,
  image,
  mobImage,
  isLottie,
  showScrollingText,
  lottieClass
}) => {
  const containerRef = useRef()
  const width = useWindowWidth()
  const [height, setHeight] = useState('fit-content')

  useEffect(() => {
    if (width < 768) {
      const container = document.getElementById('herocontainer')
      if (container) {
        setHeight('fit-content')
      }
    } else if (width >= 768 && width < 1280) {
      const container = document.getElementById('herocontainer')
      const imageContainer = document.getElementById('heroimagecontainer')
      if (imageContainer) {
        const height = imageContainer.offsetHeight
        setHeight(`${height}px`);
      }
    } else {
      setHeight('580px');
    }
  }, [width])

  return (
    <div
      className={cn(
        "w-full flex flex-col justify-start items-center gap-y-4",
        "md:relative md:flex-row md:min-h-[372px] md:items-start",
        "xl:!min-h-[580px]"
      )}
      id="herocontainer"
      ref={containerRef}
      style={{
        height: height
      }}
    >
      <HeroContent
        description={description} heading={heading}
        showScrollingText={showScrollingText}
      />
      {!isLottie && <HeroImage image={image} heading={heading} mobImage={mobImage} />}
      {!!isLottie && <HeroLottie url={image} lottieClass={lottieClass} />}
    </div>
  )
}

export default WithLayoutBorders(
  Hero,
  {
    showBgGradient: true
  }
)
